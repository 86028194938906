import * as React from "react";
import { graphql } from "gatsby";
import Dark from "../../templates/Guides/Dark/Dark";
import Light from "../../templates/Guides/Light/Light";
import Layout from "../../Layouts/Layout/Layout";
import Seo from "../../components/Seo/Seo";

export default function Guide(props) {
  const tipContent = props.data.strapiArticles.tip_content;
  const introText = props.data.strapiArticles.introduction.text;
  const theme = props.data.strapiArticles.theme;
  const articleTitle = props.data.strapiArticles.article_title;

  if (theme === "dark") {
    return (
      <Layout articleTitle={articleTitle}>
        <Seo title={articleTitle} />
        <Dark
          articleTitle={articleTitle}
          tipContent={tipContent}
          introText={introText}
        />
      </Layout>
    );
  }

  if (theme === "light") {
    return (
      <Layout articleTitle={articleTitle}>
        <Seo title={articleTitle} />
        <Light
          articleTitle={articleTitle}
          tipContent={tipContent}
          introText={introText}
        />
      </Layout>
    );
  }
}

export const query = graphql`
  query guides($id: String!) {
    strapiArticles(id: { eq: $id }) {
      tip_content
      introduction {
        text
      }
      id
      slug
      title
      article_title
      theme
    }
  }
`;
