import * as React from "react";
import CtaButton from "../CtaButton/CtaButton";
import { rrCtaContainer, rrCta } from "./RocketReferralsCta.module.scss";
/**
 * This component returns the section for "See what Rocket Referrals can do for you" section
 * It contains the header and the button component which is fed information from the page it lives on
 *
 * We can make this editable in strapi if we want.
 */

export default function RocketReferralsCta({ h2, buttonText, link }) {
  return (
    <div className={rrCtaContainer}>
      <div className={rrCta} style={{ maxWidth: "40rem" }}>
        <h2>{h2}</h2>
        <CtaButton name={buttonText} link={link} />
      </div>
    </div>
  );
}
