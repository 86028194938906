import * as React from "react";
import { ctaButton } from "./CtaButton.module.scss";
import { Link } from "gatsby";

/**
 * Since we use the same button throughout the site this can be passed
 * text, link and a full-width prop (some sections ie Indium have a full width button)
 * allows us to reuse this component anywhere on the site and pass in the appropriate information.
 *
 * Also checks for internal links. The regex is checking to see if the link begins with a / and if so return <Link> otherwise <a>
 */

export default function CtaButton({ name, link, fullWidth }) {
  const internal = /^\/(?!\/)/.test(link);

  if (internal) {
    return (
      <Link style={fullWidth ? { width: "100%" } : null} to={link}>
        <button
          style={fullWidth ? { width: "100%" } : null}
          className={ctaButton}
        >
          {name}
        </button>
      </Link>
    );
  }

  return (
    <a style={fullWidth ? { width: "100%" } : null} href={link}>
      <button
        style={fullWidth ? { width: "100%" } : null}
        className={ctaButton}
      >
        {name}
      </button>
    </a>
  );
}
