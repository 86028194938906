import * as React from "react";
import { Link } from "gatsby";
import {
  sticky,
  outerNavContainer,
  innerNavContainer,
  nav,
  sideNavLinks,
  active,
} from "./NavSteps.module.scss";

export default function NavSteps({ tips }) {
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          //convert dataset to int
          let tipNum = parseInt(entry.target.dataset.val, 10);

          /*
          Check to see if element is intersecting
          If true: check to see if y of element is > 0. This triggers at the top rather than the bottom of the element.
          If true: set to active/check
          The else-if conditional makes sure that we do not trigger an uncheck when we leave the bottom of the element
          */
          if (entry.isIntersecting === true) {
            if (entry.boundingClientRect.y > 0) {
              document
                .querySelector(`[data-tip="${tipNum}"]`)
                .classList.add(`${active}`);
            }
          } else if (entry.isIntersecting === false) {
            if (entry.boundingClientRect.y > 0)
              document
                .querySelector(`[data-tip="${tipNum}"]`)
                .classList.remove(`${active}`);
          }
        });
      },
      {
        threshold: 0,
      }
    );

    //selects all our intersection obs divs and creates an obs for each.
    const tips = document.querySelectorAll('[data-observe^="tip-"]');
    tips.forEach((tip) => observer.observe(tip));

    //reset scroll on render
    window.scrollTo(0, 0);

    return () => {
      //clean up previous observer on rerender
      observer.disconnect();
    };
  }, []);

  /**
   *
   * loops through our nav steps to determine which ones should be checked and unchecked. Not the most efficient solution.
   * For now it works without any noticeable delay or performance loss, but a longer list we could see a decrease.
   * Should potentially look into refactoring dependent on if we plan to have an extremely length article.
   *
   */

  async function activateLinks(e) {
    const tipNum = parseInt(e.target.dataset.tip, 10);

    if (
      !document
        .querySelector(`[data-tip="${tipNum}"]`)
        .classList.contains(`${active}`)
    ) {
      for (let i = 1; i < tipNum; i++) {
        document.querySelector(`[data-tip="${i}"]`).classList.add(`${active}`);
      }
    } else {
      for (let i = tips.length; i > tipNum - 1; i--) {
        document

          .querySelector(`[data-tip="${i}"]`)
          .classList.remove(`${active}`);
      }
    }
  }

  return (
    <div className={`wrapper ${sticky}`}>
      <div className={outerNavContainer}>
        <div className={innerNavContainer}>
          <nav className={nav}>
            {tips.map((tip) => {
              // tips is essentially a tuple that returns the number of the tip + the name of the tip.
              const [tipNum, tipName] = tip;
              return (
                <Link
                  key={`tip-${tipNum}`}
                  onClick={activateLinks}
                  className={sideNavLinks}
                  data-tip={tipNum}
                  to={`#tip-${tipNum}`}
                >
                  {tipName}
                </Link>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
}
