export function cardColor(type) {
  const cardColors = {
    commercial: "#4E2B71",
    personal: "#38B1B3",
    new: "#EFB800",
  };

  return cardColors[type];
}

export function npsScoreColor(score) {
  switch (true) {
    case score < 60:
      return {
        name: "red",
        hex: "#EA444D",
      };
    case score < 71:
      return {
        name: "yellow",
        hex: "#EFB800",
      };
    case score < 80:
      return {
        name: "blue",
        hex: "#0069B4",
      };
    default:
      return {
        name: "green",
        hex: "#38B1B3",
      };
  }
}

export function tipColor(theme) {
  switch (theme) {
    case "blue":
      return {
        backgroundColor: "#0069b4",
        color: "#FFF",
        tipNumberColor: "#FFF",
      };
    case "dark_blue":
      return {
        backgroundColor: "#054372",
        color: "#FFF",
        tipNumberColor: "#FFF",
      };
    case "green":
      return {
        backgroundColor: "#38b1b3",
        color: "#FFF",
        tipNumberColor: "#FFF",
      };
    case "light":
      return {
        backgroundColor: "#ecf2f9",
        color: "#054372",
        tipNumberColor: "#0069b4",
      };
    case "purple":
      return {
        backgroundColor: "#4e2b71",
        color: "#FFF",
        tipNumberColor: "#FFF",
      };
    default:
      return {
        backgroundColor: "#ecf2f9",
        color: "#054372",
        tipNumberColor: "#0069b4",
      };
  }
}
