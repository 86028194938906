import * as React from "react";
import ReactMarkdown from "react-markdown";
import RocketReferralsCta from "../../../components/RocketReferralsCta/RocketReferralsCta";
import NavSteps from "../../../components/NavSteps/NavSteps";
import { tipColor } from "../../../utilities/colors";
import { StaticImage } from "gatsby-plugin-image";

import {
  skyLightBackground,
  skyLight,
  sky,
  cloud,
  mountainRight,
  mountainLeft,
} from "../../../styles/design.module.scss";

import {
  titleContainer,
  contentColumn,
  contentColumnContainer,
  tipContainer,
  tipText,
  tipImageContainer,
  imgContainer,
  introductionColumn,
} from "./Light.module.scss";

export default function Light({ articleTitle, tipContent, introText }) {
  let tipCount = 0; //no state since we don't want rerender on change.
  const tips = [];

  // returns the main paragraph content

  function loadParagraph(p) {
    return (
      <div className="wrapper" key={`${p.strapi_component}-${p.id}`}>
        <div className={contentColumnContainer}>
          <div className={contentColumn}>
            <ReactMarkdown
              transformImageUri={(uri) =>
                uri.startsWith("http") ? uri : `http://localhost:1337${uri}`
              }
              children={p.Paragraph}
            />
          </div>
        </div>
      </div>
    );
  }

  // returns the tip section to the content column. Additional containers provided to allow full stretch background.

  function loadTip(tip) {
    tipCount += 1;
    const tipID = `tip-${tipCount}`;
    tips.push([tipCount, tip.title]);
    const tipImageURL = tip.image?.url; //`http://localhost:1337${tip.tip_image?.url}`

    return (
      <section id={tipID} key={`${tip.strapi_component}-${tip.id}`}>
        <div style={{ backgroundColor: tipColor(tip.theme).backgroundColor }}>
          <div className="wrapper">
            <div className={contentColumnContainer}>
              <div className={contentColumn}>
                <div className={tipContainer}>
                  <div className={tipText}>
                    <h5
                      style={{ color: tipColor(tip.theme).tipNumberColor }}
                    >{`Tip #${tipCount}`}</h5>
                    <h2 style={{ color: tipColor(tip.theme).color }}>
                      {tip.title}
                    </h2>
                  </div>
                  <div className={tipImageContainer}>
                    <img
                      src={tipImageURL}
                      alt=""
                      style={{
                        maxWidth: "20rem",
                        maxHeight: "17.5rem",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loadParagraph(tip.content)}
        <div data-observe={tipID} data-val={tipCount}></div>
      </section>
    );
  }

  return (
    <>
      <div
        style={{
          position: "relative",
          marginTop: "-4.5rem",
          paddingTop: "4.5rem",
        }}
      >
        <div className={`${skyLight} ${skyLightBackground}`}>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
        </div>
        <section className={`${titleContainer} wrapper position-zindex`}>
          <h1>{`Guide: ${articleTitle}`}</h1>
          <div className={imgContainer}>
            <StaticImage
              objectFit="contain"
              quality="100"
              placeholder="none"
              src="../../../images/promoter_header.svg"
              alt=""
            />
          </div>
        </section>
      </div>
      <section
        className="background-white"
        style={{ position: "relative", zIndex: "1" }}
      >
        <article>
          <div className="wrapper">
            <div className={contentColumnContainer}>
              <div className={`${contentColumn} ${introductionColumn}`}>
                <ReactMarkdown
                  transformImageUri={(uri) =>
                    uri.startsWith("http") ? uri : `http://localhost:1337${uri}`
                  }
                >
                  {introText}
                </ReactMarkdown>
              </div>
            </div>
          </div>
          <NavSteps tips={tips} />
          {tipContent.map((content) => {
            return loadTip(content);
          })}
        </article>
      </section>
      <div style={{ position: "relative" }}>
        <div className={sky}>
          <div
            className="wrapper"
            style={{ position: "relative", zIndex: "1500", height: "100%" }}
          >
            <StaticImage
              className={mountainLeft}
              objectFit="contain"
              placeholder="none"
              quality="100"
              src="../../../images/mountain_left.svg"
              alt=""
            />
            <StaticImage
              className={mountainRight}
              objectFit="contain"
              placeholder="none"
              quality="100"
              src="../../../images/mountain_right.svg"
              alt=""
            />
          </div>

          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
        </div>
        <RocketReferralsCta
          h2="We can make NPS work for you."
          buttonText="Check out Rocket Referrals"
          link="https://www.rocketreferrals.com/features"
        />
      </div>
    </>
  );
}
